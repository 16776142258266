import axios from 'axios';
import { clsx } from 'clsx';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { IS_SERVER } from './localStorage';

export const downloadURI = async (imageUrl, name) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    const url = `${imageUrl}?not-from-cache-please`;

    await downloadImageURL(url, name);
  } else {
    try {
      const response = await axios.post('/api/aws/get-signed-url', {
        imageName: name,
        imageUrl,
      });

      const data = response.data;
      return downloadImageURL(data.signedUrl, name);
    } catch (error) {
      console.error('Error getting signedUrl:', error);
    }
  }
};

export const downloadImageURL = async (imageUrl, name) => {
  try {
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'image/jpeg',
      },
      crossdomain: true,
    });

    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

export const formatExpiration = (expirationDateString) => {
  const expirationTime = moment(
    `${expirationDateString} 23:59:59`,
    'YYYY-MM-DD HH:mm:ss'
  );
  const currentTime = moment();
  const timeDifference = expirationTime.diff(currentTime, 'milliseconds');

  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days >= 1) {
    return `Expires in ${days} day${days > 1 ? 's' : ''}`;
  } else if (hours >= 1) {
    return `Expires in ${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (minutes >= 1) {
    return `Expires in ${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return 'Expires soon';
  }
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Helper function to find minimum and maximum values, handling NaN
export const findMinMax = (arr) => {
  let result = {
    minimumDays: Infinity,
    maximumDays: -Infinity,
  };

  for (const item of arr) {
    // Check for NaN
    if (!isNaN(item.minimumDays)) {
      result.minimumDays = Math.min(result.minimumDays, item.minimumDays);
    }

    // Check for NaN
    if (!isNaN(item.maximumDays)) {
      result.maximumDays = Math.max(result.maximumDays, item.maximumDays);
    }
  }

  return result;
};

export const sleep = (delay = 2000) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

export const getURL = () => {
  if (IS_SERVER) return '';

  return window.location.origin;
};

export const generateRandomString = (limit = 6) => {
  const characters =
    'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz23456789';
  let result = '';

  for (let i = 0; i < limit; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

/**
 * Returns the last item in a string split by a specified delimiter.
 * @param {string} value - The string to be split.
 * @param {string} splitBy - The delimiter used to split the string.
 * @returns {string} The last element of the splitted string.
 */
export function getLastElementInSplitString(value, splitBy) {
  if (!value) return '';

  const splittedString = value.split(splitBy);
  const lastElement = splittedString[splittedString.length - 1];

  return lastElement;
}

export const generateReferralCode = (limit = 6) => {
  const characters =
    'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz23456789';
  let result = '';

  for (let i = 0; i < limit; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const handleScrollToTop = (top) => window.scrollTo({ top: top ?? 0 });

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error('Invalid file type'));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
