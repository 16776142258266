export const GTM_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_MEASUREMENT_ID;

export const pageview = (url) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const trackBeginCheckout = ({ total, coupon, items }) => {
  const orderedItems = items.map((item) => {
    const uuid = crypto.randomUUID();

    return {
      item_id: uuid,
      item_name: item.name,
      price: item.price / 100,
      quantity: item.quantity,
      item_category: item?.category,
    };
  });

  window.gtag('event', 'begin_checkout', {
    event_category: 'ecommerce',
    event_label: 'Track Begin Checkout',
    currency: 'USD',
    value: total / 100,
    coupon,
    items: orderedItems,
  });
};

export const trackPurchase = ({ total, coupon, items }) => {
  const orderedItems = items.map((item) => {
    const uuid = crypto.randomUUID();

    return {
      item_id: uuid,
      item_name: item.name,
      price: item.price / 100,
      quantity: item.quantity,
      item_category: item?.category,
    };
  });

  window.gtag('event', 'purchase', {
    // event_category: 'ecommerce',
    event_label: 'Purchased Publication',
    currency: 'USD',
    value: total / 100,
    coupon,
    items: orderedItems,
  });
};
