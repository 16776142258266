import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { getLastElementInSplitString } from '../utils/generalUtils';
import {
  IS_SERVER,
  getLocalStorage,
  setLocalStorage,
} from '../utils/localStorage';

/**
 * @typedef {Object} ReferralInstanceContextType
 * @property {string} referralCode - Referral code.
 * If the referral code begins with aff, it means affiliate, or reg, it means regular (not an affiliate).
 * referref_id is before the period.
 */

/**
 * Context for managing referral instances.
 * @type {React.Context<ReferralInstanceContextType>}
 */
export const ReferralInstanceContext = createContext({
  referralCode: '',
});

export const REFERRAL_KEY = 'presscart-referral_code';

/**
 * Provider component for managing referral instances.
 * @param {{ children: React.ReactNode }} props - The children components.
 */
export const ReferralProvider = ({ children }) => {
  const [referralCode, setReferralCode] = useState('');
  const router = useRouter();

  useEffect(() => {
    const hasReferralQuery = router?.query?.ref;

    if (hasReferralQuery !== undefined && hasReferralQuery !== '') {
      setLocalStorage(REFERRAL_KEY, JSON.stringify(hasReferralQuery));
    }
  }, [router?.query]);

  useEffect(() => {
    if (IS_SERVER) return;

    const referral = getLocalStorage(REFERRAL_KEY);

    if (!referral) return setReferralCode('');

    setReferralCode(JSON.parse(referral));
  }, [router?.query]);

  return (
    <ReferralInstanceContext.Provider value={{ referralCode }}>
      {children}
    </ReferralInstanceContext.Provider>
  );
};

/**
 * Custom hook to access the referral context.
 * @returns {{ referralCode: string, isAffiliate: boolean, referrerId: string }} The referral code and isAffiliate which check if referral_code is an affiliate or regular(non-affiliate)
 */
export const useReferral = () => {
  const { referralCode } = useContext(ReferralInstanceContext);
  const isAffiliate = referralCode?.startsWith('aff') ?? false;
  const referrerId = getLastElementInSplitString(referralCode, '.');

  return { referralCode, isAffiliate, referrerId };
};
