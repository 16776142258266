/**
 * @type {boolean} Flag indicating whether the code is running on the server.
 */
export const IS_SERVER = typeof window === 'undefined';

/**
 * Sets a value in the local storage.
 * @param {string} key - The key for the local storage entry.
 * @param {*} value - The value to be stored.
 */
export function setLocalStorage(key, value) {
  if (IS_SERVER) return;
  localStorage[key] = value;
}

/**
 * Retrieves a value from the local storage.
 * @param {string} key - The key for the local storage entry.
 * @returns {string} The retrieved value.
 */
export function getLocalStorage(key) {
  if (IS_SERVER) return '';
  if (!localStorage[key]) return '';
  return localStorage[key];
}

/**
 * Retrieves all items in the local storage.
 * @template T
 * @returns {T} All items in the local storage.
 */
export function listOfLocalStorage() {
  if (IS_SERVER) return {};
  return localStorage;
}

/**
 * Clears all items in the local storage.
 */
export function clearLocalStorage() {
  if (IS_SERVER) return;
  localStorage.clear();
}

/**
 * Removes an item from the local storage.
 * @param {string} key - The key of the item to remove.
 */
export function removeItemLocalStorage(key) {
  if (IS_SERVER) return;
  localStorage.removeItem(key);
}
